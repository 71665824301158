














































































































































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import Spinners from "@/components/utilities/Spinners.vue";
import PosterCard from "@/components/shared/PosterCard.vue";
import PostersModule from "@/store/vuex-modules/posters";
import PollPanel from "@/components/sessions/PollPanel.vue";

import { PosterObject } from "@/types/interfaces";

const posterStore = getModule(PostersModule);

export default Vue.extend({
    data() {
        return {
            searchString: "",
            localeTimezone: "",
            tagFilter: "",
            votingModalOpen: false
        };
    },
    components: {
        PosterCard,
        Spinners,
        PollPanel
    },
    computed: {
        /**
         * Computed
         */
        tag3FilterOptions(): Array<string | undefined> {
            const arr = this.allPosters.flatMap((tag) => {
                return tag.tags ? tag.tags : [];
            });
            return [...new Set(arr)].sort();
        },
        pageOptions(): Record<string, any> {
            return this.$store.getters.getPageOptions("posters") || {};
        },

        sessionForPoll() {
            return {
                sessionId: "fake-session-for-polls",
                sessionName: "Poster Voting",
                endTime: ""
            };
        },

        votingOptions(): Record<string, any> {
            return this.pageOptions.voting
                ? this.pageOptions.voting
                : {
                      enabled: false
                  };
        },

        posterData(): Array<PosterObject> {
            let filteredPosters =
                this.allPosters && this.allPosters.length
                    ? this.allPosters
                    : [];

            if (this.categoryFilter) {
                filteredPosters = filteredPosters.filter(
                    (poster: PosterObject) =>
                        poster.categories &&
                        poster.categories.includes(this.categoryFilter)
                );
            }

            if (this.tagFilter) {
                filteredPosters = filteredPosters.filter(
                    (poster: PosterObject) =>
                        poster.tags && poster.tags.includes(this.tagFilter)
                );
            }

            if (this.searchTerm) {
                filteredPosters = filteredPosters.filter(
                    (poster: PosterObject) =>
                        poster.title
                            .toLowerCase()
                            .includes(this.searchTerm.toLowerCase()) ||
                        (poster.description &&
                            poster.description
                                .toLowerCase()
                                .includes(this.searchTerm.toLowerCase()))
                );
            }

            return filteredPosters.sort((a: PosterObject, b: PosterObject) =>
                a.title.localeCompare(b.title)
            );
        },

        isFiltered(): boolean {
            return this.posterData.length !== this.allPosters.length;
        },

        textHeader(): string {
            const pText = this.posterData.length !== 1 ? "posters" : "poster";
            let text = `${this.posterData.length} ${pText} found `;

            if (this.categoryFilter) {
                text += `for Category: ${this.categoryFilter}`;
            }

            if (this.categoryFilter && this.searchTerm) {
                text += " and ";
            }

            if (this.searchTerm) {
                text += `containing "${this.searchTerm}"`;
            }

            return text;
        },

        categoryList(): Array<string> {
            const categories: Array<string> = [];

            this.allPosters.forEach((poster) => {
                if (poster.categories && poster.categories.length) {
                    poster.categories.forEach((category) => {
                        if (!categories.includes(category)) {
                            categories.push(category);
                        }
                    });
                }
            });

            return categories.sort();
        },
        tagsFilterCategory(): string {
            return this.$route.query && this.$route.query.tags
                ? this.$route.query.tags.toString()
                : "";
        },

        categoryFilter(): string {
            return this.$route.query && this.$route.query.category
                ? this.$route.query.category.toString()
                : "";
        },

        searchTerm(): string {
            return this.$route.query && this.$route.query.term
                ? this.$route.query.term.toString()
                : "";
        },

        isLoading(): boolean {
            return !posterStore.postersLoaded;
        },

        allPosters(): Array<PosterObject> {
            return posterStore.posters.length ? posterStore.posters : [];
        },

        helpTextContext(): string {
            let returnValue = "";

            if (
                this.pageOptions.helpText &&
                this.pageOptions.helpText.context
            ) {
                returnValue = this.pageOptions.helpText.context;
            }

            return returnValue;
        },

        disclaimerText(): string {
            return this.pageOptions.disclaimerText
                ? this.pageOptions.disclaimerText
                : "";
        }
    },

    /**
     * Lifecycle
     */
    created() {
        posterStore.getPosters();
        this.searchString = this.searchTerm;
        this.logPageVisit();
    },
    methods: {
        /**
         * Methods
         */
        updateCategory(category: string) {
            const query = this.$route.query ? { ...this.$route.query } : {};
            query.category = category;

            this.$router.push({
                query: query
            });
        },
        updateTopicFilter(tag: string) {
            const query = this.$route.query ? { ...this.$route.query } : {};
            query.topic = tag;

            this.$router.push({
                query: query
            });
        },

        resetSearch() {
            this.searchString = "";
            this.tagFilter = "";
            this.$router.push("/posters");
        },

        updateTerm(term: string) {
            const query = this.$route.query ? { ...this.$route.query } : {};
            query.term = term;

            this.$router.push({
                query: query
            });
        },

        logPageVisit() {
            const logData = {
                type: `PosterLanding`
            };

            this.$store
                .dispatch("appendLogEntry", logData)
                .catch((err) => console.log(err));
        }
    }
});
